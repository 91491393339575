
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import RegenerateThumbs from '@/components/buttons/RegenerateThumbs.vue';

  const wsStore = namespace('ws');

  @Component({ components: { RegenerateThumbs } })
  export default class WsPage extends Vue {
    orderId = '';

    @wsStore.Action
    sendInfoAboutNewVersion!: (hard: boolean) => void;

    @wsStore.Action
    sendTestOrder!: (orderId: number) => void;

    notifyAboutNewVersion(hard = false): void {
      confirm(this.$t('general.areYouSure') as string) && this.sendInfoAboutNewVersion(hard);
    }

    redirect(): void {
      const url = process.env.VUE_APP_APIURL + 'queues' 
      window.location.href=url
    }

    sendOrder(): void {
      confirm(this.$t('general.areYouSure') as string) && this.sendTestOrder(+this.orderId);
    }
  }
